.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 26px;
}

.list {
    gap: 24px;

    &_item {
        color: var(--highlight);
        min-width: 80px;
        text-align: center;
        line-height: 1.3;
    }
}