@media screen and (min-width:992px) {
    .first-col-firstrow-dashboard-wrapper {
        height: calc(100% - 262px);
    }

    .latest-news-dashboard-wrapper .card {
        height: inherit !important;
    }
}

@media screen and (min-width:1100px) {
    .List-Col-2 {
        width: 20%;
    }
}

@media screen and (max-width:1200px) {
    .auth-rightside-card {
        padding: 25px !important;
    }

    .auth-rightside-card h1 {
        margin-top: 5px !important;
    }

    .signin-option-col-4 .signin-option-btn {
        height: 40px !important;
        font-size: 12px !important;
    }

    .login-btn-icon {
        width: 10px !important;
        margin-right: 6px !important;
    }

    .auth-right-footer-row {
        margin-top: 20px !important;
    }

    .lgmb-3 {
        margin-bottom: 1rem !important;
    }
}

@media screen and (max-width:1100px) {
    .super-match-details {
        gap: 25px;
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
}

@media screen and (max-width:991px) {
    .tmb-3 {
        margin-bottom: 1rem !important;
    }

    .card-h-50 {
        height: inherit !important;
    }

    .tab-list li .tab-link {
        padding: 0 40px 8px !important;
    }
}

@media screen and (max-width:767px) {
    .mmb-3 {
        margin-bottom: 1rem !important;
    }

    .team-main-profile-card {
        height: auto;
    }

    ul.pagination-list {
        justify-content: center !important;
    }
    .page-sub-headerbar,
    .right-side-top-actions {
        flex-direction: column;
    }
    .page-sub-headerbar h2 {
        display: flex;
        align-self: flex-start;
        margin-bottom: 15px;
    }
    .mobile-flex-align-self-end {
        align-self: flex-end;
    }
    .sort-lbl {
        margin: 15px 0 !important;
    }
    .right-side-top-actions .ms-3 {
        margin-left: 0 !important;
        margin-top: 15px;
    }
}

@media screen and (max-width:600px) {
    .super-match-details {
        flex-direction: column;
    }

    .super-match-details .last-wrapper-super-match-details {
        align-items: center !important;
        justify-content: center !important;
        text-align: center !important;
    }

    .merch-mobile-subtitle {
        margin-bottom: 10px;
    }
}

@media (max-width: 575px) {
    .news-article .title {
        font-size: 12px !important;
        margin-bottom: 0 !important;
    }

    .news-article .match-title {
        font-size: 14px !important;
    }

    .user-profile-card .profile-details .profile-avatar {
        width: 70px !important;
        height: 70px !important;
    }

    .user-profile-card .profile-details .profile-avatar {
        width: 100px !important;
        height: 100px !important;
    }

    .user-profile-card .stats-item:not(:last-child) {
        padding-right: 16px !important;
        margin-right: 16px !important;
    }

    .schedule-card .card-bg::after {
        background: linear-gradient(0deg, var(--color-blue1) 0%, rgba(12, 20, 29, 0) 100%) !important;
    }

    .schedule-card .schedule-card-body {
        padding: 0 16px 16px !important;
    }

    .auth-wrapper-row {
        margin-left: 0;
        margin-right: 0;
    }
}

@media (max-width: 400px) {
    .home-main-match-bottom-details {
        flex-direction: column;
    }

    .home-main-match-bottom-details h5 {
        margin-bottom: 10px;
    }
}

@media screen and (min-width:1200px) and (max-width:1400px) {

    .dashboard-col-1,
    .dashboard-col-2,
    .dashboard-col-3 {
        height: 810px !important;
    }
}

@media screen and (min-width:1200px) and (max-width:1500px) {
    .video-highlights-dashboard-wrapper .video-avatar {
        width: 40px !important;
    }

    .video-highlights-dashboard-wrapper .video-highlight-card-right {
        width: calc(100% - 40px) !important;
    }

    .video-highlights-dashboard-wrapper .video-highlight-card-right .match-team-name {
        margin-left: 0 !important;
        line-height: normal;
    }
}

@media screen and (min-width:992px) and (max-width:1200px) {
    .latest-news-dashboard-wrapper {
        margin-top: 1rem !important;
        order: 2 !important;
    }

    .last-col-dashboard-wrapper {
        order: 1 !important;
    }
}

@media screen and (min-width:900px) and (max-width:1100px) {
    .main-upcoming-match-vs-box {
        padding-left: 10px;
        padding-right: 10px;
    }

    .main-upcoming-match-vs-box .fs-18 {
        font-size: 12px !important;
    }
}

@media screen and (min-width:768px) and (max-width:900px) {
    .super-match-details .UM-big-avatar {
        width: 30px;
    }

    .super-match-details .fs-22 {
        font-size: 14px !important;
    }

    .super-match-details .fs-14 {
        font-size: 12px !important;
    }

    .super-match-details .fs-28 {
        font-size: 16px !important;
    }

    .super-match-details .fs-18 {
        font-size: 12px !important;
    }
}