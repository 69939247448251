.card {
  &_img {
    height: 170px;
  }

  &_social {
    padding: 8px 20px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}