.wrapper {
    display: flex;
    justify-content: space-between;
    gap: 24px;
}

.media {
    width: 110px;
    height: 110px;
    align-self: flex-end;
    padding-right: 5px;

    // iPhone XR
    @media screen and (min-width: 414px) {
        width: 150px;
        height: 150px;
    }
}

.main {
    height: auto;
    max-width: calc(100% - 144px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    &.ltr {
        padding: 1rem 0 1rem 1rem;
    }

    &.rtl {
        padding: 1rem 1rem 1rem 0;
    }

    &_title {
        max-width: 180px;
    }
}