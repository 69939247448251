.title {
    max-width: 180px;
    width: 100%;
}

.swiper {
    margin: 20px 0 27px;

    &_slide {
        border-radius: 8px;
        overflow: hidden;
        height: 260px;

        img {
            height: 100%;
        }
    }
}