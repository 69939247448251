@import "styles/reset";
@import "styles/keyframes";
@import "styles/utils";
@import "styles/elements";
@import "styles/typo";
@import "styles/vars";
@import "styles/rtl";
@import "styles/vars";
@import "styles/global";
@import "fonts/icomoon/style.css";
@import "styles/responsive.scss";
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');

body,
h1,
h2,
h3,
h4,
h5,
h6,
button,
a,
span,
p {
    font-family: "Rajdhani", sans-serif !important;
}

:root {
    --color-blue1: #0C141D;
    --color-blue2: #4A5E75;
    --color-blue3: #8FA6BA;
    --color-blue4: #212D47;
    --color-blue5: #1E2C3A;
    --color-blue6: #182533;
    --color-red: #FF4656;
    --color-red2: #FF465685;
    --color-red3: #D23838;
    --color-white: #fff;
    --color-black: #000;
    --color-black2: #0F1923;
    --color-grey: #8FA6BA;
    --badge-green: #38D263;
    --badge-green2: #44CF2E;
    --badge-yellow1: #EBBB14;
    --badge-yellow2: #BF9C20;
    --badge-neutral: #7B7B7B;
    --badge-error: #D23838;
    --btn-primary-bg: #FF4656;
    --btn-secondary-bg: #8B8B8B;
    --btn-secondary-bg2: rgba(139, 139, 139, 0.52);
    --border-dark-blue: #232D45;
}

// Common CSS - Start
body {
    font-family: var(--body-font);
}

.logo-main {
    object-fit: contain;
}

.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-22 {
    font-size: 22px !important;
}

.fs-24 {
    font-size: 24px !important;
}

.fs-26 {
    font-size: 26px;
}

.fs-28 {
    font-size: 28px;
}

.fs-30 {
    font-size: 30px;
}

.fs-32 {
    font-size: 32px;
}

.fs-34 {
    font-size: 34px;
}

.fs-36 {
    font-size: 36px;
}

.fs-38 {
    font-size: 38px;
}

.fs-40 {
    font-size: 40px;
}

.fs-48 {
    font-size: 48px;
}

.h-auto {
    height: auto !important;
}

.text-lg {
    font-size: 1.125rem;
}

.text-left {
    text-align: left !important;
}

.line-clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-clamp-1 {
    -webkit-line-clamp: 1;
}

.line-clamp-2 {
    -webkit-line-clamp: 2;
}

.line-clamp-3 {
    -webkit-line-clamp: 3;
}

.line-clamp-4 {
    -webkit-line-clamp: 4;
}

.bg-blue1 {
    background-color: var(--color-blue1);
}

.text-blue2 {
    color: var(--color-blue2);
}

.text-blue3 {
    color: var(--color-blue3);
}

.border-blue2 {
    border-color: var(--color-blue2) !important;
}

.border-blue4 {
    border-color: var(--color-blue4) !important;
}

.border-red {
    border-color: var(--color-red) !important;
}

.text-accent {
    color: var(--color-red) !important;
}

.text-green {
    color: var(--badge-green) !important;
}

.text-grey {
    color: var(--color-grey);
}

.card {
    background: var(--color-blue1) !important;
}

.bg-primary {
    background-color: var(--color-blue1) !important;
}

.bg-secondary {
    background-color: var(--color-blue5) !important;
}

.badge {
    font-size: 10px;
    line-height: 1;
    padding: 4px 10px;
    border-radius: 24px;
    color: var(--color-white);
    cursor: auto;
}

.badge.text {
    padding: 0;
    border-radius: 0;
}

.badge-green {
    background-color: var(--badge-green) !important;
}

.badge-neutral {
    background-color: var(--badge-neutral) !important;
}

.badge-error {
    background-color: var(--badge-error) !important;
}

.badge-green.text {
    color: var(--badge-green) !important;
}

.badge-neutral.text {
    color: var(--badge-neutral) !important;
}

.badge-error.text {
    color: var(--badge-error) !important;
}

.btn-primary-bg {
    background-color: var(--btn-primary-bg) !important;
}

.btn-secondary-bg {
    background-color: var(--btn-secondary-bg) !important;
}

.pl-50 {
    padding-left: 50px !important;
}

.h-80 {
    height: 80px;
}

.no-aspect-ratio {
    aspect-ratio: inherit !important;
}

.w-200px {
    width: 200px;
}

.top-right-red-action-button {
    width: 280px;
}

.top-right-back-action-button {
    width: 120px;
}

.g-25{
    gap: 25px;
}

.upload-file-wrapper {
    border: 1px dashed var(--color-red);
    color: var(--color-red);
    min-height: 110px;
    flex: 1;
}

.uploaded-file-wrapper {
    width: 200px;
    height: 110px;
}

.uploaded-file-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

// Table CSS
.table-responsive {
    width: 100%;
    overflow: auto;
    white-space: nowrap;
    border: 1px solid var(--color-grey);
}

.table-responsive .table {
    border: none;
}

.table-responsive .table tr {
    border-bottom: 1px solid var(--color-blue6);
}

.table-responsive .table thead tr {
    border: none;
    border-bottom: 1px solid var(--color-grey);
}

.table-responsive .table tr th,
.table-responsive .table tr td {
    font-size: 16px;
    line-height: 1.25;
    font-weight: 600;
    border: none;
    border-right: 1px solid var(--color-grey);
    background-color: transparent;
    vertical-align: middle;
}

.table-responsive .table tr th {
    color: var(--color-grey);
    padding: 16px;
    position: relative;
}

.table-responsive .table tr td {
    color: var(--color-white);
    padding: 12px 16px;
}

.table-responsive .table tr td,
.table-responsive .table tr td *:not(img, .avatar-sm, .avatar-xs, .material-symbols-outlined) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 350px;
    min-width: 100px;
}

.table-responsive .table tr th.sorting::after {
    content: '';
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-image: url(assets/sorting-icon.svg);
    background-size: 6px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.table-responsive .table tbody tr:last-child,
.table-responsive .table tr th:last-child,
.table-responsive .table tr td:last-child {
    border: none;
}

.table-responsive.tournament-result .table tbody tr:nth-child(n + 1) td:nth-child(2) {
    background-color: var(--color-grey);
}

.table-responsive.tournament-result .table tbody tr:nth-child(2n) td:nth-child(2) {
    background-color: rgba(143, 166, 186, 0.50);
}

// Custom progress bar
.progress-bar {
    width: 100%;
    height: 6px;
    background-color: var(--color-blue5);
    border-radius: 0;
}

.progress-bar .progress {
    background-color: var(--badge-green2);
    border-radius: 0;
}

// Input CSS
.field {
    background-color: var(--color-blue1);
    color: var(--color-white) !important;
    border: 0 none !important;
    border-radius: 0 !important;
    font-size: 16px !important;
    font-weight: 700 !important;
}

.field:placeholder-shown {
    text-transform: uppercase !important;
    color: var(--color-blue2) !important;
    --placeholder: var(--color-blue2) !important;
}

.theme-form-select .field-input {
    background-color: var(--color-blue1) !important;
    color: var(--color-white) !important;
    border: 0 none !important;
    border-radius: 0 !important;
    font-size: 16px !important;
    font-weight: 700 !important;
}

.theme-form-select .gZillP .select__placeholder {
    color: var(--color-blue2) !important;
}

.form-box .inner-text,
.form-box .inner-icon {
    right: 12px;
}

.form-box.big-inner-text .field {
    padding-right: 84px !important;
}

.custom-select {
    width: 100%;
    display: block;
    font-size: 16px;
    line-height: 1.25;
    color: var(--color-white);
    padding: 9px 16px;
    padding-right: 30px;
    background-color: var(--color-blue6);
    border: none;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("data:image/svg+xml;utf8,<svg fill='none' width='10' height='5' viewBox='0 0 10 5' xmlns='http://www.w3.org/2000/svg'><path d='M5 5L10 0L0 -4.37114e-07L5 5Z' fill='white' /></svg>") no-repeat right 12px center var(--color-blue6);
    box-shadow: none;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// common button css
.btn-primary-new {
    background-color: var(--btn-primary-bg) !important;
    box-shadow: 0px 0px 17.1px 0px var(--color-red2);
    border-radius: 0 !important;
    justify-content: space-between !important;
    align-items: center !important;
    text-transform: uppercase;
    height: 53px !important;
    color: var(--color-white) !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    width: 100%;
}

.card-h-50{
    height: calc(50% - 10px);
}

.searchbar-wrapper {
    padding: 8px 8px 8px 10px;
    width: 220px;
    border: 1px solid var(--color-grey);
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 14px;
}

// Common CSS - End


// Dashboard Schedule CSS - Start
.match-day {
    color: var(--color-blue2);
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.match-team-name {
    font-size: 12px;
    margin-left: 8px;
}

.match-time {
    font-size: 11px;
}

.match-avatar {
    width: 18px;
    height: 18px;
    object-fit: contain;
}

// Dashboard Schedule CSS - End


// Video Highlight CSS - Start
.video-highlight-card-right {
    margin-left: 12px;
    width: calc(100% - 90px);
}

.video-highlight-card .title {
    color: var(--color-blue2);
    font-size: 12px;
    margin-bottom: 0;
}

.video-highlight-card .match-title {
    color: var(--color-white);
    font-size: 14px;
    font-weight: 600;
}

.video-avatar {
    width: 90px;
    object-fit: contain;
}

.main-video-wrapper {
    padding: var(--card-padding) var(--card-padding) 24px;
}

// Video Highlight CSS - End


// Tournaments Tabs CSS - Start
.Tournaments-Tabs-Card .card-padded {
    border-top: 3px solid var(--accent);
    padding: 0 !important;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow-x: auto;
}

.Tournaments-Tabs {
    display: flex !important;
    gap: 2px !important;
}

.Tournaments-Tabs .btn {
    background-color: transparent !important;
    border-radius: 0 !important;
    height: auto !important;
    padding: 7px !important;
    display: flex !important;
    min-width: auto !important;
    max-width: inherit !important;
    text-overflow: inherit !important;
    overflow: auto !important;
    font-size: 11px !important;
    line-height: 1 !important;
    font-weight: 400 !important;
}

.Tournaments-Tabs .btn:hover,
.Tournaments-Tabs .btn.active {
    color: var(--color-white) !important;
    background-color: var(--accent) !important;
}

// Tournaments Tabs CSS - End


// Latest News CSS - Start
.main-news-wrapper {
    padding-bottom: 5px !important;
}

.main-news-card-right {
    margin-top: 10px;
    margin-left: 0 !important;
}

.main-news-card-avatar {
    width: 100% !important;
}

.main-news-card-right .title,
.main-news-card-right .match-title {
    margin-left: 0 !important;
}

.main-news-card-right .title {
    margin-bottom: 0px !important;
}

// Latest News CSS - End


// Dashboard Video CSS - Start
.main-video-left-big iframe {
    width: 100%;
    height: 398px;
}

.main-match-video-teams .match-avatar {
    width: 22px;
    height: 22px;
}

.main-match-video-teams .match-team-name {
    font-size: 24px;
}

// Dashboard Video CSS - End

// Shop page css -  Start

.category-link li a {
    color: var(--color-red);
}

// Shop page css - End

// Auth[Login-Signup] related CSS - Start
.styles_container__VtjaR {
    grid-template-columns: inherit !important;
}

.styles_cover__imjwC {
    display: none !important;
}

.styles_form__8xMUo {
    background-color: transparent !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    padding: 0 !important;
    max-width: 100% !important;
}

.gradient-bg {
    background: linear-gradient(113deg, #0F1923 0.74%, #1C1D3B 100%);

    &.sidebar {
        background: linear-gradient(0deg, #0F1923 0.74%, #1C1D3B 100%);
    }
}

.main-layout-auth {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(112.74deg, #0F1923 0.74%, #1C1D3B 100%);

    &::before {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        content: "";
        background: url('../src/assets/bg-login.svg');
        background-repeat: no-repeat;
        z-index: 0;
        background-position: 100%;
        background-size: 100% 100%;
    }
}

.auth-main-container {
    max-height: 760px;
    position: relative;
}

.auth-col-right form {
    width: 100% !important;
}
.auth-card-wrap {
    background: linear-gradient(202.06deg, #1C1C3A 0.7%, #0F1923 98.47%);
}
.auth-rightside-card {
    /*background: linear-gradient(202.06deg, #1C1C3A 0.7%, #0F1923 98.47%);*/
    padding: 30px 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.auth-rightside-card-bg {
    background: linear-gradient(202.06deg, #1C1C3A 0.7%, #0F1923 98.47%);
}

.signin-option-row {
    margin-right: calc(-.2* var(--bs-gutter-x));
    margin-left: calc(-.2* var(--bs-gutter-x));
    width: 100%;
}

.signin-option-col-4 {
    padding-right: calc(var(--bs-gutter-x)* .2);
    padding-left: calc(var(--bs-gutter-x)* .2);
}

.signin-option-btn {
    height: 55px;
}

.login-btn-icon {
    width: 20px;
    margin-right: 10px;
}

.or-lines {
    // width: 160px;
    width: 48%;
}

.field-wrapper .field-btn {
    display: none;
}

.main-logo {
    width: 90px;
}

// Auth[Login-Signup] related CSS - End


.UM-table tr td {
    border-bottom-color: var(--color-blue3);
    background-color: transparent !important;
}

.UM-small-avatar {
    width: 40px;
    object-fit: contain;
}

.UM-big-avatar {
    width: 80px;
    object-fit: contain;
}

.super-match-details {
    border-color: var(--color-blue3) !important;
}

.upcoming-matches-main-card .video-highlight-card-right {
    width: 100%;
}

.card-live-video {
    width: 100%;
    height: 500px;
}

.slide-team-player-img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 5px;
}

.team-logo-img img,
.tiw-card .league-header img {
    object-fit: contain;
}

.ptl-img img {
    height: auto;
    margin: 0 auto;
}

.left-side-title {
    transform: rotate(-90deg) translateY(-50%);
    -o-transform: rotate(-90deg) translateY(-50%);
    -moz-transform: rotate(-90deg) translateY(-50%);
    -webkit-transform: rotate(-90deg) translateY(-50%);
    position: absolute;
    padding: 0;
    font-size: 28px;
    top: calc(50% - 16px);
}

.stat-small-avatar {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
}

.player-info-header-wrap .square-avatar {
    width: 100px;
}

.player-info-header-wrap img {
    width: 100%;
}

// Tournament Bracket css - START
.tournament-round {
    width: calc(27% - 30px);
    margin-right: 30px;
}

.tournament-match {
    border: 1px solid var(--color-blue2);
    border-radius: 8px;
    margin: 20px 0;
    width: 100%;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1;
}

.tournament-match-team {
    position: relative;
    z-index: 1;
}

.vs-line-wrap {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    padding: 3px 15px;
    background-color: var(--color-blue1);
    z-index: 2;
}

// connecting lines for bracketlist
.tournament-match-lines {
    display: block;
    position: absolute;
    top: 50%;
    margin-top: 0px;
    left: -16px;
    width: 100%;
    z-index: 0;
}

.tournament-match-lines .tournament-match-line {
    position: absolute;
    background: var(--color-blue2);
}

.tournament-match-lines .tournament-match-line-one {
    height: 1px;
    width: calc(100% + 33px);
}

.tournament-match-lines .tournament-match-line-two {
    height: 140px;
    width: 1px;
    right: -33px;
}

.tournament-match:nth-child(even) .tournament-match-lines .tournament-match-line-two {
    transform: translate(0, -100%);
}

.tournament-round:nth-child(1) .tournament-match-lines {
    left: 0;
}

.tournament-round:nth-child(1) .tournament-match-lines .tournament-match-line-one {
    width: calc(100% + 16px);
}

.tournament-round:nth-child(1) .tournament-match-lines .tournament-match-line-two {
    right: -16px;
}

.tournament-round:nth-child(2) .tournament-match-lines .tournament-match-line-two {
    height: 220px;
}

.tournament-round:nth-child(3) .tournament-match-lines .tournament-match-line-one {
    width: calc(100% + 48px);
}

.tournament-round:nth-child(3) .tournament-match-lines .tournament-match-line-two {
    display: none;
}

// Tournament Bracket css - End

// News page CSS - Start
.news-article .title {
    font-size: 18px;
    margin-bottom: 8px !important;
}

.news-article .match-title {
    font-size: 24px;
}

.news-banner-img,
.news-banner-video {
    aspect-ratio: 1;
}

// News page CSS - End

// Post Page CSS - Start
.post-bg-banner {
    height: 350px;
}

.post-card .title {
    font-size: 14px;
    margin-bottom: 4px !important;
}

.post-card .match-title {
    font-size: 18px;
}

// Post Page CSS - End

.pagination-list .btn.active {
    color: var(--btn-primary-bg);
    background-color: transparent;
    border-color: var(--btn-primary-bg);
}

// Profile Page CSS - Start
.user-profile-card .profile-details {
    transform: translateY(-30px);
}

.user-profile-card .profile-img .change-profile {
    right: 4px;
    bottom: 0;
}

.user-profile-card .profile-details .profile-avatar {
    width: 143px;
    height: 143px;
    border-radius: 100%;
}

.user-profile-card .stats-item {
    position: relative;
}

.user-profile-card .stats-item:not(:last-child) {
    padding-right: 25px;
    margin-right: 25px;
}

.user-profile-card .stats-item:not(:last-child)::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 1px;
    height: 22px;
    background-color: var(--color-grey);
    transform: translateY(-50%);
}

.wallet-card .card-bg-img {
    height: 60px;
}

.wallet-card .card-bg-img::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    width: 100%;
    height: 90px;
    background: linear-gradient(0deg, var(--color-blue1) 0%, rgba(12, 20, 29, 0.00) 100%);
}

.schedule-card .card-bg-arrow {
    position: absolute;
    right: 0;
    bottom: 0;
}

.schedule-card .card-bg::after {
    content: '';
    position: absolute;
    width: 400px;
    height: 100%;
    top: 0;
    right: 0;
    background: linear-gradient(270deg, var(--color-blue1) 0%, rgba(12, 20, 29, 0.00) 100%);
}

.schedule-card .schedule-card-body {
    padding: 16px 32px 24px;
    padding-left: 0;
}

.schedule-card .timer-block {
    max-width: 210px;
    border-top: 1px solid var(--color-blue6);
}

.team-card .card-bg-img {
    position: absolute;
    width: 100%;
    height: 55%;
    top: 0;
    left: 0;
    right: 0;
}

.team-card .card-bg-img::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 180px;
    background: linear-gradient(0deg, var(--color-blue1) 0%, rgba(12, 20, 29, 0.00) 100%);
}

.profile-tabs {
    overflow-x: auto;
    overflow-y: hidden;
}

.profile-tabs::-webkit-scrollbar,
.wallet-row .token-dropdown .dropdown-list::-webkit-scrollbar {
    display: none;
}

.tab-list li .tab-link {
    font-size: 16px;
    line-height: 1.15;
    font-weight: 600;
    padding: 0 72px;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--color-blue6);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.tab-list li .tab-link.active {
    color: var(--color-white);
    border-color: var(--color-red);
}

.ratio-block .gradient-block {
    padding: 16px 20px;
    border-right: 4px solid var(--color-red3);
    background: linear-gradient(90deg, rgba(210, 56, 56, 0.00) 0%, rgba(158, 49, 49, 0.62) 100%);
}

.ratio-block .gradient-block.today {
    border-right: 4px solid var(--badge-green);
    background: linear-gradient(90deg, rgba(56, 210, 99, 0.00) 0%, rgba(45, 159, 77, 0.49) 100%);
}

// Profile Page CSS - End

// Product Display CSS - START
.merchandising-details-thumb-img {
    height: 130px;
    width: 100%;
    object-fit: cover;
}

// Product Display CSS - END

// Settings CSS - START
.settings-main-wrapper .field {
    border: 1px solid var(--color-blue2) !important;
}

.settings-main-wrapper .select__control {
    border-radius: 0 !important;
    color: var(--color-blue2) !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    border: 1px solid var(--color-blue2) !important;
}

.settings-main-wrapper .select__control div {
    color: var(--color-blue2) !important;
}

// Settings CSS - END

// Popular Posts CSS - Start
.Popular-Posts-Wrapper .video-highlight-card-right {
    margin-left: 0 !important;
    width: 100% !important;
}

// Popular Posts CSS - End

// Wallet CSS - Start
.wallet-row .token-dropdown {
    background-color: var(--color-blue1);
    z-index: 9;
}

.wallet-row .token-dropdown .dropdown-text {
    gap: 8px;
    font-size: 16px;
    line-height: 1.25;
    font-weight: 600;
    color: var(--color-white);
    padding: 4px 8px;
    padding-right: 4px;
    border: 0.5px solid var(--color-grey);
    height: 33px;
    cursor: pointer;
}

.wallet-row .token-dropdown.open .arrow {
    transform: rotate(180deg);
}

.wallet-row .token-dropdown .dropdown-list {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    width: 100%;
    border: 0.5px solid var(--color-grey);
    background-color: var(--color-blue1);
    max-height: 150px;
    overflow-x: hidden;
    overflow-y: auto;
    display: none;
}

.wallet-row .token-dropdown.open .dropdown-list {
    display: block;
}

.wallet-row .dropdown-list .dropdown-link {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 16px;
    line-height: 1.25;
    font-weight: 600;
    color: var(--color-white);
    padding: 6px 8px;
    border-bottom: 1px solid var(--color-blue6);
    cursor: pointer;
}

.wallet-row .dropdown-list .dropdown-link:last-child {
    border: none;
}

.wallet-row .wallet-title {
    height: 33px;
}

// Wallet CSS - END


// Product Display Component CSS - Start
.big-product-img {
    height: calc(100% - 130px);
}

.big-product-img img{
    height: 100%;
    width: 100%;
    border-radius: 8px;
}

.thumb-product-small-img {
    height: 130px;
    width: 100%;
}

.thumb-product-small-img img{
    height: 100%;
}
// Product Display Component CSS - END


// Common Date picker calendar icon css
input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(37%) sepia(10%) saturate(1206%) hue-rotate(172deg) brightness(93%) contrast(92%); /* Inverts the colors of the calendar icon */
}

// List of Team Component CSS - Start
.list-team-card-row .view-action-buttons{
    display: flex !important;
}
// List of Team Component CSS - END

// User Team Profile Component for Member card CSS - Start
.invited-member-profile .invited-box-wrapper {
    display: flex;
}

.invited-box-wrapper {
    background-color: rgba(25, 29, 55, 0.85);
    z-index: 999;
    position: absolute;
    left: 0;
    top: 0;
    display: none;
}
// User Team Profile Component for Member card CSS - END


.Single-Tournament-Card-MainImg {
    max-height: 195px;
}

.auth-wrapper-row {
    box-shadow: 0px 0px 31.7px 0px rgba(0, 0, 0, 0.34);
}

.dashboard-latest-news-section {
    padding-top: 5px;
}

.gap-0 {
    gap: 0 !important;
}

.border-dark-blue {
    border-color: var(--border-dark-blue) !important;
}

.settings-nav-tab button {
    background-color: transparent !important;
    color: var(--color-red);
    border-color: var(--color-red) !important;
}

.text-description {
    font-weight: 400 !important;
}

.settings-main-wrapper .select__menu {
    border-radius: 0 !important;
    background-color: var(--color-blue1) !important;
    border-color: var(--color-blue2) !important;
    color: var(--color-blue2) !important;
}

.settings-main-wrapper .select__control div.select__single-value {
    color: var(--color-white) !important;
}