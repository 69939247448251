/* utility classes */

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.d-block {
    display: block;
}

.d-grid {
    display: grid;
}

.grid-col-1 {
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: repeat(2, minmax(0, 1fr));
}

.grid-col-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-col-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.d-flex {
    display: flex;
}

.d-inline-flex {
    display: inline-flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-column {
    flex-direction: column;
}

.flex-row-reverse {
    flex-direction: row-reverse;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-end {
    justify-content: flex-end;
}

.align-items-center {
    align-items: center;
}

.align-items-start {
    align-items: flex-start;
}

.align-items-end {
    align-items: flex-end;
}

.flex-1 {
    flex: 1;
}

.g-1 {
    gap: 1px;
}

.g-2 {
    gap: 2px;
}

.g-4 {
    gap: 4px;
}

.g-6 {
    gap: 6px;
}

.g-8 {
    gap: 8px;
}

.g-10 {
    gap: 10px;
}

.g-12 {
    gap: 12px;
}

.g-14 {
    gap: 14px;
}

.g-16 {
    gap: 16px;
}

.g-20 {
    gap: 20px;
}

.g-24 {
    gap: 24px;
}

.g-30 {
    gap: 30px;
}

.g-40 {
    gap: 40px;
}

.g-50 {
    gap: 50px;
}

.g-60 {
    gap: 60px;
}

.g-70 {
    gap: 70px;
}

.p-relative {
    position: relative;
}

.z-2 {
    z-index: 2;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-header {
    color: var(--header);
}

.text-right {
    text-align: right;
}

.text-black {
    color: #000;
}

.text-highlight {
    color: var(--highlight);
}

.text-10 {
    font-size: 0.625rem;
}

.text-12 {
    font-size: 0.75rem;
}

.text-600 {
    font-weight: 600;
}

.text-700 {
    font-weight: 700;
}

.border-top {
    border-top: 1px solid var(--border);
}

.border-color-bottom {
    border-bottom: 4px solid var(--border);

    .card_footer--sm {
        height: 69px;
    }
}

.border-4 {
    border-radius: 4px;
}

.border-8 {
    border-radius: 8px;
}

.h-1 {
    height: calc(220px + var(--widget-scale)) !important;
}

.h-2 {
    height: calc(460px + var(--widget-scale)) !important;
}

.h-3 {
    height: calc(700px + var(--widget-scale)) !important;
}

.h-4 {
    height: calc(942px + var(--widget-scale)) !important;
}

.h-fit {
    height: fit-content !important;
}

.c-pointer {
    cursor: pointer;
}

.card-padded {
    padding: var(--card-padding);
}

.mt-5 {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-25 {
    margin-top: 25px;
}

.mt-30 {
    margin-top: 30px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-15 {
    margin-bottom: 15px;
}

.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px;
}

.border,
.border-top,
.border-bottom,
.border-start,
.border-end {
    border-color: var(--color-blue3) !important;
}

.avatar-xxxs {
    width: 14px !important;
    height: 14px !important;
}

.avatar-xxs {
    width: 16px !important;
    height: 16px !important;
}

.avatar-xs {
    width: 24px !important;
    height: 24px !important;
}

.avatar-sm {
    width: 32px !important;
    height: 32px !important;
}

.avatar-md {
    width: 48px !important;
    height: 48px !important;
}

.avatar-lg {
    width: 64px !important;
    height: 64px !important;
}

.avatar-xl {
    width: 72px !important;
    height: 72px !important;
}

.avatar-xxl {
    width: 96px !important;
    height: 96px !important;
}

.inner-scroll-wrapper {
    position: relative;
    -webkit-box-flex: 1;
    flex-grow: 1;
    width: 100%;
}

.inner-scroll-wrapper:after {
    content: "";
    display: block;
    height: 100px;
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0;
    z-index: 300;
    transition: all 0.3s ease-in-out;
    background: linear-gradient(rgba(17, 19, 18, 0) 0%, rgb(17, 19, 18) 100%);
}

.inner-scroll {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
}

.inner-scroll::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar {
    display: none;
}

.cursor-pointer {
    cursor: pointer;
}