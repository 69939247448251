.control {
    position: absolute;
    height: fit-content;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    color: var(--highlight);
    display: flex;
    align-items: center;

    &.ltr {
        &.left {
            left: 0;
            padding-left: 8px;
            justify-content: flex-start;
        }

        &.right {
            right: 0;
            padding-right: 8px;
            justify-content: flex-end;
        }
    }

    &.rtl {
        &.left {
            right: 0;
            padding-right: 8px;
            justify-content: flex-start;
        }

        &.right {
            left: 0;
            padding-left: 8px;
            justify-content: flex-end;
        }
    }

    button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        background-color: rgba(255, 0, 0, 0.15);
        backdrop-filter: blur(5px);
        border-radius: 100%;
        transition: all var(--transition);

        i {
            color: var(--accent);
            opacity: 0.5;
            transition: inherit;
        }

        &:hover {
            i {
                opacity: 1;
            }
        }
    }
}