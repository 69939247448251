/* typography and text related utility classes */
@font-face {
    font-family: 'BigNoodleTitling';
    src: url('../fonts/BigNoodleTitling.eot');
    src: url('../fonts/BigNoodleTitling.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BigNoodleTitling.woff2') format('woff2'),
        url('../fonts/BigNoodleTitling.woff') format('woff'),
        url('../fonts/BigNoodleTitling.ttf') format('truetype'),
        url('../fonts/BigNoodleTitling.svg#BigNoodleTitling') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: var(--heading-font);
    font-weight: 700;
    color: var(--header);
    margin: 0;

    &.light {
        color: var(--header-light);
    }

    &.dark {
        color: var(--header-dark);
    }
}

h1,
.h1 {
    font-size: 1.75rem;
    line-height: 1.25;

    &.large {
        font-size: 3rem;
        height: 3rem;
        display: inline-flex;
        align-items: center;
    }
}

h2,
.h2 {
    font-size: 1.375rem;
}

h3,
.h3 {
    font-size: 1rem;
    line-height: 1.25rem;
}

h4,
.h4 {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25rem;
}

h5,
.h5 {
    font-size: 0.75rem;
    font-weight: 600;
}

h6,
.h6 {
    font-size: 0.5625rem;
    font-weight: 600;
}

ul,
p {
    padding: 0;
    margin: 0;
}

.heading-font {
    font-family: var(--heading-font);
}

.secondary-font {
    font-family: 'BigNoodleTitling' !important;
}

.text-16 {
    font-size: 1rem;
}

.text-20 {
    font-size: 1.25rem;
    line-height: 1.2;
}

.text-center {
    text-align: center;
}

.lh-1 {
    line-height: 1;
}

.gradient-text {
    display: inline-block;
    background: linear-gradient(92deg, var(--badge-yellow1) 1.14%, var(--badge-yellow2) 98.9%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}