.container {
    grid-template-columns: minmax(0, 1fr);

    @media screen and (min-width: 768px) {
        grid-template-columns: minmax(0, 1fr) 1px minmax(0, 1fr);
        gap: 30px;
    }
}

.divider {
    height: 100%;
    width: 1px;
    background: var(--border);
}

.media {
    gap: 16px;
    margin-bottom: 20px;

    &_main {
        aspect-ratio: 329/414;
        max-height: 414px;
        width: 100%;
        overflow: hidden;
        border-radius: 8px;
        margin-bottom: 10px;
    }
}

.thumb {
    cursor: pointer;

    &.ltr {
        margin-right: 12px !important;
        margin-left: 0 !important;
    }

    &.rtl {
        margin-left: 12px !important;
        margin-right: 0 !important;
    }
}

.footer {
    padding-top: 30px;

    &_details {
        padding-top: 30px;
    }
}

// tablet portrait
@media screen and (min-width: 768px) {
    .media {
        margin-bottom: 0;

        &_main {
            margin-bottom: 34px;
        }
    }

    .thumb {
        &.ltr {
            margin-right: 15px !important;
        }

        &.rtl {
            margin-left: 15px !important;
        }
    }

    .footer_details {
        padding-top: 30px;
    }
}