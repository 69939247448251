.container {
    display: grid;
    min-height: unset;
}

.media {
    display: none;

    img {
        height: 100%;
        width: auto;
    }
}

.main {
    &_info {
        border-bottom: 1px solid var(--border);
        padding-bottom: 18px;
        margin-bottom: var(--card-padding);
    }
}

// desktop
@media screen and (min-width: 1280px) {
    .container {
        grid-template-columns: minmax(0, 150px) minmax(0, calc(100% - 150px));
        justify-content: space-between;
    }

    .media {
        max-height: 160px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

// desktop large
@media screen and (min-width: 1500px) {
    .container {
        grid-template-columns: minmax(0, 1fr);
    }

    .media {
        display: none;
    }
}

@media screen and (min-width: 1700px) {
    .container {
        grid-template-columns: minmax(0, 150px) minmax(0, calc(100% - 150px));
    }

    .media {
        display: flex;
    }
}