.rating {
  &_card {
    &_body {
      padding: 25px 36px;
    }

    &_shape {
      &_top {
        position: absolute;
        left: 0;
        top: 0;
      }

      &_bottom {
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }

    &_bg {
      width: 105px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: 100% 0 0 100%;
    }

    &_triangle {
      &_bg {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 150px;
      }
    }
  }
}

.ratio {
  &_indicator {
    width: 6px;
    height: 6px;
    background-color: var(--badge-green);
  }
}

.import {
  &_square {
    width: 110px;
    height: 110px;

  }

  &_bordered {
    width: 110px;
    height: 110px;
    border: 1px dashed var(--color-red);
  }
}

.card {
  &_img {
    height: 150px;
  }
}