.list_item {
    position: relative;
    cursor: grab;
    background: var(--widget);
    padding: 0 22px;
    overflow: visible;

    * {
        user-select: none !important;
    }

    input {
        cursor: grab;

        &.title {
            max-width: 150px;
            margin-top: -2px;

            // iPhone XR
            @media screen and (min-width: 414px) {
                max-width: 250px;
            }
        }
    }

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        display: block;
        border-radius: 8px;
        height: 100%;
        box-sizing: border-box;
        top: 0;
        left: 0;
        border: 1px solid var(--border);
        z-index: 1;
        background: var(--widget);
        opacity: 0;
        transition: opacity var(--transition);
    }

    .category {
        display: flex;
        align-items: center;
        gap: 6px;

        &_label {
            opacity: 0;
            transition: opacity var(--transition);
        }
    }

    .secondary {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        margin-top: 4px;
        flex-shrink: 0;
    }

    .content {
        position: relative;
        z-index: 2;
        display: flex;
        gap: 12px;
        padding: 20px 0;
        border-bottom: 1px solid var(--border);
        transition: border-color var(--transition);

        &:hover {
            border-color: transparent;
        }
    }

    .delete {
        opacity: 0;
        transition: opacity var(--transition);
        color: var(--highlight) !important;
    }

    &:hover {
        &:after,
        .category_label,
        .delete {
            opacity: 1;
        }
    }
}

.planner_item {
    padding: 15px;
    cursor: grab;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid transparent;
    transition: all var(--transition);

    &.light {
        background-color: var(--body);
    }

    &.dark {
        background-color: var(--border);
    }

    &.checked {
        background-color: var(--widget);
        border-color: var(--border);
    }

    .title {
        font-size: 0.875rem;
        font-weight: 600;
        color: var(--header);
    }

    .timestamp {
        color: #fff !important;
    }

    // iPhone XR
    @media screen and (min-width: 414px) {
        padding: 20px 24px;
    }
}

.category_color {
    width: 8px;
    height: 8px;
    border-radius: 50%;
}