.container:not(:last-child) {
    padding-bottom: 24px;
    border-bottom: 1px solid var(--border);
}

.rating {
    margin: 16px 0;
}

.footer {
    display: flex;
    flex-direction: column;
    gap: 15px;

    // iPhone XR
    @media screen and (min-width: 414px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}

.thumbs {
    position: relative;
    width: 16px;
    height: 16px;
    font-size: 16px;
    line-height: 1;

    &.up {
        color: var(--grass);
    }

    &.down {
        color: var(--red);
    }

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: opacity var(--transition);
        opacity: 0;

        &.active {
            opacity: 1;
        }
    }
}