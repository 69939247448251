.media {
    width: 80px;
    height: 80px;
    display: none !important;

    &.compact {
        width: 40px;
        height: 40px;
    }

    // iPhone XR
    @media screen and (min-width: 414px) {
        display: flex !important;
        flex-shrink: 0;
    }
}