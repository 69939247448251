.slide {
  height: 300px;
}

.main {
  * {
    position: relative;
    z-index: 2;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 220px;
    background: linear-gradient(0deg, rgba(10, 0, 0, 0.95) 41.1%, rgba(0, 0, 0, 0.0001) 100%)
  }
}