/* global template styles */

html {
    user-select: none;
    font-size: calc(100% * var(--font-scale));

    &.no-transition *:not(.swiper:not(.selection-list) *) {
        transition: none !important;
    }

    &.no-scroll {
        overflow: hidden;
    }
}

body {
    background: var(--body);
    color: var(--text);
    font-family: var(--body-font);
    font-size: 0.875rem;
    line-height: 20px;
}

.app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    &_container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex: 1;
        margin: 15px 15px 70px;
    }

    &.fluid {
        display: block;

        .app_container {
            margin: 0;
        }
    }

    .layout {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    @media screen and (min-width: 768px) {
        .app_container {
            margin-bottom: 40px;
        }

        .layout {
            margin: -20px -25px;
        }
    }

    @media screen and (min-width: 1400px) {
        display: grid;
        grid-template-columns: minmax(0, 220px) minmax(0, (calc(100% - 220px)));


        .app_container {
            margin: 30px 40px 40px;
            grid-area: 1 / 2 / 2 / 3;
        }
    }
}

.lazy-image {
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
        height: 100%;
    }
}

input, input::placeholder {
    color: var(--text);
}